import React, { useState } from 'react';
import Modal from 'react-modal';
import { Providers } from '@microsoft/mgt-element';
import { CircularProgress, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box, TextField } from '@mui/material';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";
import { Alert } from '@mui/material';

const ReservationConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  onCancel,
  reservedDate,
  reservedAmPm,
  startDate,
  endDate,
  deskid,
  userPrincipalName,
  displayName,
  worktype,
  parameters,
}) => {

  const [reservationComplete, setReservationComplete] = useState(false);
  const [reservationStatus, setReservationStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeOption, setTimeOption] = useState(worktype === "shareroom" ? "custom" : reservedAmPm); // 選択肢の初期値は終日
  const [customStartTime, setCustomStartTime] = useState('09:00'); // カスタム開始時間の初期値
  const [customEndTime, setCustomEndTime] = useState('17:30'); // カスタム終了時間の初期値
  const [reservedTime, setReservedTime] = useState(null);
  
  const reservationStatusMessage = {
    "success": "以下の予約が完了しました。",
    "fail": "以下の予約は出来ませんでした。再度予約操作を実施してください。",
    "": "",
  };

  if (!deskid && !userPrincipalName) return null;

  Modal.setAppElement('body');

  const subject = () => {
    if (deskid){
      return `予約済：${deskid}`;
    }else{
      return `予約済：${displayName}`;
    }
  }
  
  const emailAddress = () => {
    if (deskid){
      return {
        address: `${deskid}@${parameters['accountDomian']}`,
        name: `${deskid}`
      }
    }else{
      return {
        address: `${userPrincipalName}`,
        name: `${userPrincipalName.split('@')[0]}`
      }
    }
  }

  const shareResourceAccount = deskid ? `${deskid}@${parameters['accountDomian']}` : userPrincipalName;

  const convertTime = (time) => {
    // 先頭のゼロを削除
    return time.replace(/^0+/, '');
  };

  const selectTime = (time) => {
    if (time === 'allday'){
      return '終日';
    }
    else if (time === 'am'){
      // return 'AM';
      return '9:00-12:00';
    }
    else if (time === 'pm'){
      // return 'PM';
      return '13:00-17:30';
    }
    else{
      return time;
    }
  };
  
  const handleConfirm = async () => {
    setLoading(true);
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = await microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });

      let event = {
        subject: subject(),
        start: {
          dateTime: `${startDate}`,
          timeZone: 'Asia/Tokyo'
        },
        end: {
          dateTime: `${endDate}`,
          timeZone: 'Asia/Tokyo'
        },
        isAllDay: true, // デフォルトは終日
        showAs: 'free',
        responseRequested: false,
        attendees: [
          {
            emailAddress: emailAddress(),
            type: 'resource',
          }
        ]
      };

      // 時間オプションに応じてeventの内容を変更
      if (timeOption === 'am') {
        event.start.dateTime = `${reservedDate}T09:00:00`;
        event.end.dateTime = `${reservedDate}T12:00:00`;
        event.isAllDay = false;
        setReservedTime("9:00-12:00");
      } else if (timeOption === 'pm') {
        event.start.dateTime = `${reservedDate}T13:00:00`;
        event.end.dateTime = `${reservedDate}T17:30:00`;
        event.isAllDay = false;
        setReservedTime("13:00-17:30");
      } else if (timeOption === 'custom') {
        event.start.dateTime = `${reservedDate}T${customStartTime}:00`;
        event.end.dateTime = `${reservedDate}T${customEndTime}:00`;
        event.isAllDay = false;
        setReservedTime(`${convertTime(customStartTime)}-${convertTime(customEndTime)}`);
      }

      const responseData = await client.api('/me/events').post(event);
      const eventId = responseData.id;
      const eventUid = responseData.iCalUId;

      setTimeout(async () => {
        try {
          const resourceEvents = await client.api(`/users/${shareResourceAccount}/events`).get();
          const matchingEvent = resourceEvents.value.find(event => event.iCalUId === eventUid);

          if (matchingEvent) {
            setReservationStatus('success');
          } else {
            setReservationStatus('fail');
            await client.api(`/me/events/${eventId}`).delete();
          }

          setReservationComplete(true);
          setLoading(false);
          onConfirm();
        } catch (error) {
          console.error('Error fetching resource events', error);
          setReservationStatus('fail');
          setReservationComplete(true);
          setLoading(false);
        }
      }, 5000);
    } catch (error) {
      console.error('Error POST data', error);
      setReservationStatus('fail');
      setReservationComplete(true);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="予約確認"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      {reservationComplete ? (
        <div>
          {reservationStatus === 'fail' && (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
              {reservationStatusMessage[reservationStatus]}
            </Alert>
          )}
          {reservationStatus === 'success' && (
            <p>{reservationStatusMessage[reservationStatus]}</p>
          )}
          <div style={{ display: 'box', justifyContent: 'flex-start' }}>
            <div style={{ textAlign: 'left', marginLeft: '20px' }}>
              <div>
                予約日：{reservedDate}
              </div>
              <div>
                時間：{reservedTime ? reservedTime : "終日"}
              </div>
              <div>
                場所：{worktype === "shareroom" ? displayName : deskid + "席"}
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={onRequestClose}
            style={{ padding: '6px 12px', marginTop: '20px', marginRight: '5px' }}
            size="small"
          >
            閉じる
          </Button>
        </div>
      ) : (
        <div>
          {loading ? (
            <div>
              <CircularProgress />
              <p>予約処理中…</p>
            </div>
          ) : (
            <>
              {worktype === "shareroom" ? (
                <p>{reservedDate} に {displayName} を予約します</p>
              ):(
                <p>{reservedDate} ({selectTime(reservedAmPm)}) に {deskid}席 を予約します</p>
              )}
              
              {/* 時間選択オプション */}
              {worktype === "shareroom" && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">時間帯を選択してください</FormLabel>
                  <RadioGroup
                    aria-label="timeOption"
                    name="timeOption"
                    value={timeOption}
                    onChange={(e) => setTimeOption(e.target.value)}
                  >
                    <FormControlLabel value="allday" control={<Radio />} label="終日" />
                    <FormControlLabel value="am" control={<Radio />} label="AMのみ（9:00～12:00）" />
                    <FormControlLabel value="pm" control={<Radio />} label="PMのみ（13:00～17:30）" />
                    <FormControlLabel value="custom" control={<Radio />} label="カスタム時間" />
                  </RadioGroup>
                  {timeOption === 'custom' && (
                    <Box mt={2}>
                      <TextField
                        label="開始時間"
                        type="time"
                        value={customStartTime}
                        onChange={(e) => setCustomStartTime(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5分間隔
                        }}
                        style={{ width: '115px', marginBottom: '5px' }}
                      />
                      <TextField
                        label="終了時間"
                        type="time"
                        value={customEndTime}
                        onChange={(e) => setCustomEndTime(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5分間隔
                        }}
                        style={{ width: '115px', marginLeft: '10px' }}
                      />
                    </Box>
                  )}
                </FormControl>
              )}

              {/* ボタン */}
              <Box mt={3} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                  style={{ padding: '6px 12px', marginRight: '10px' }}
                  size="small"
                >
                  予約する
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCancel}
                  style={{ padding: '6px 12px' }}
                  size="small"
                >
                  CANCEL
                </Button>
              </Box>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ReservationConfirmationModal;

